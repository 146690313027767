import React, { useRef } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SlButton from "../../components/button";
import Hero from "../../components/hero";
import CallNowBanner from "../../components/callNowBanner";
import QuickActions from "../../components/quickActions";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import LogoSchema from "../../components/Schema/logo";
import CallIcon from "../../components/call-icon";
import Layout from "../../components/layout";
import AreaMap from "../../components/areaMap";
import { checkPropertiesForNull } from "../../utils/object";
import { SafeHtmlParser } from "../../components/safe-html-parser";

const WhatWeDo = () => {
	const data = useStaticQuery(
		graphql`
			{
				site {
					siteMetadata {
						siteUrl
					}
				}
				reviewData: wpPage(slug: { eq: "home" }) {
					reviewCountFields {
						googleReviewCount
						bwcReviewCount
						checkatradeReviewCount
					}
				}

				schemaLogo: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
				pageData: wpPage(slug: { eq: "what-we-do" }) {
					seoFieldGroups {
						localBusinessSchema
						metaDescription
						metaTitle
						openGraphDescription
						openGraphTitle
						productSchema
						image {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
					whatWeDoPageFieldGroups {
						whatWeDoBannerSection {
							heading
							description
							cta {
								target
								title
								url
							}
							actions {
								heading
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						whatWeDoCtaSection1 {
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
						}
						whatWeDoCtaSection2 {
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
						}
						whatWeDoGoogleMapSection {
							googleMap
							heading
						}
						whatWeDoServicesSection {
							serviceList {
								heading
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
								cta {
									target
									title
									url
								}
							}
						}
						whatWeDoTextSection1 {
							description
							heading
						}
						whatWeDoTextSection2 {
							description
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
						}
						whatWeDoTextSection3 {
							heading
							description
							cta {
								target
								title
								url
							}
						}
						whatWeDoTwoColumnSection {
							heading
							description
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							cta {
								target
								title
								url
							}
						}
					}
				}
			}
		`
	);

	const ref = useRef();

	const {
		pageData: { seoFieldGroups, whatWeDoPageFieldGroups },
		schemaLogo,
		reviewData,
		site,
		reviews,
	} = data;

	const siteUrl = site.siteMetadata.siteUrl;

	const reviewCount =
		reviewData.reviewCountFields.checkatradeReviewCount +
		reviewData.reviewCountFields.googleReviewCount +
		reviewData.reviewCountFields.bwcReviewCount;
	const schemaLogoUrl = schemaLogo.publicURL;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}/what-we-do`,
		image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
		mpn: `${siteUrl}/what-we-do`,
		sku: `${siteUrl}/what-we-do`,
		description: `${seoFieldGroups?.productSchema}`,
		logo: `${siteUrl}${schemaLogoUrl}`,
		name: "Seymour Locksmiths",
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${schemaLogoUrl}`,
			image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
			name: "Seymour Locksmiths",
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/our-locksmith-reviews`,
			datePublished: "2022-01-08T17:58:29+00:00",
			reviewBody:
				"What a fantastic service. I snapped the key in a lock that had been faulty for a while. Jeff arrived quickly and replaced the lock with plenty of spare keys and at a reasonable price. Nice friendly chap. I would definitely recommend and use again in the future. Thank you!",
			author: {
				"@type": "Person",
				name: "Francesca Richards",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Seymour Locksmiths",
				sameAs: `${siteUrl}`,
			},
		},
	};

	const {
		whatWeDoBannerSection,
		whatWeDoCtaSection1,
		whatWeDoCtaSection2,
		whatWeDoGoogleMapSection,
		whatWeDoServicesSection,
		whatWeDoTextSection1,
		whatWeDoTextSection2,
		whatWeDoTextSection3,
		whatWeDoTwoColumnSection,
	} = whatWeDoPageFieldGroups;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Seymour Locksmiths",
				item: "https://www.seymour-locksmiths.co.uk",
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Services",
				item: "https://www.seymour-locksmiths.co.uk/what-we-do",
			},
		],
	};

	return (
		<Layout>
			<CallIcon />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{seoFieldGroups?.productSchema?.length > 0 && (
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(serviceSchema)}
					</script>
				</Helmet>
			)}

			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/what-we-do`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@jefflocksmith",
					site: "@jefflocksmith",
					cardType: "summary",
				}}
			/>

			{whatWeDoBannerSection &&
				!checkPropertiesForNull(whatWeDoBannerSection, ["heading"]) && (
					<Hero
						Tag="section"
						text={
							<SafeHtmlParser
								htmlContent={whatWeDoBannerSection?.description}
							/>
						}
						image={
							whatWeDoBannerSection?.backgroundImage.localFile.childImageSharp
								.gatsbyImageData
						}
						heading={whatWeDoBannerSection?.heading}
						scrollBHidden={true}
						hFontSize="text-4xl"
						button={whatWeDoBannerSection?.cta}
						actions={whatWeDoBannerSection?.actions}
					/>
				)}

			{whatWeDoTextSection1 &&
				!checkPropertiesForNull(whatWeDoTextSection1, ["heading"]) && (
					<Container fluid={true} className="mt-24" as="section" ref={ref}>
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<Row className="mb-5">
									<Col
										className="d-flex justify-content-center text-center"
										xs={12}
									>
										<h2 className="text-3xl mb-3 fw-700 lato">
											{whatWeDoTextSection1?.heading}
										</h2>
									</Col>
									<Col className="d-flex justify-content-center" xs={12}>
										<span className="sl-underline d-block" />
									</Col>
								</Row>
								{whatWeDoTextSection1?.description && (
									<SafeHtmlParser
										htmlContent={whatWeDoTextSection1?.description}
									/>
								)}
							</Col>
						</Row>
					</Container>
				)}
			<QuickActions serviceSectionList={whatWeDoServicesSection.serviceList} />

			{whatWeDoCtaSection1 &&
				!checkPropertiesForNull(whatWeDoCtaSection1, ["heading"]) && (
					<CallNowBanner
						heading={whatWeDoCtaSection1?.heading}
						button={whatWeDoCtaSection1?.cta1}
						button2={whatWeDoCtaSection1?.cta2}
					/>
				)}
			{whatWeDoTextSection2 &&
				!checkPropertiesForNull(whatWeDoTextSection2, ["heading"]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<h2 className="text-3xl lato fw-700 mb-3">
									{whatWeDoTextSection2?.heading}
								</h2>
								<span className="sl-underline d-block mb-5" />
								{whatWeDoTextSection2?.description && (
									<SafeHtmlParser
										htmlContent={whatWeDoTextSection2?.description}
									/>
								)}
								<Row>
									{whatWeDoTextSection2.cta1 &&
										whatWeDoTextSection2.cta1.url && (
											<Col xs={12} sm="auto">
												<SlButton
													className="home-button text-lg fw-700"
													as={Link}
													to={whatWeDoTextSection2.cta1?.url}
												>
													{whatWeDoTextSection2.cta1?.title}
												</SlButton>
											</Col>
										)}
									{whatWeDoTextSection2.cta2 &&
										whatWeDoTextSection2.cta2.url && (
											<Col xs={12} sm="auto">
												<SlButton
													variant="outline-primary"
													className="text-lg fw-700"
													as={Link}
													to={whatWeDoTextSection2.cta2?.url}
												>
													{whatWeDoTextSection2.cta2.title}
												</SlButton>
											</Col>
										)}
								</Row>
							</Col>
						</Row>
					</Container>
				)}

			{whatWeDoTwoColumnSection &&
				!checkPropertiesForNull(whatWeDoTwoColumnSection, ["heading"]) && (
					<Container as="section" fluid={true}>
						<Row>
							<Col xs={12} xl={6} className="px-0">
								<GatsbyImage
									image={
										whatWeDoTwoColumnSection.image?.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={whatWeDoTwoColumnSection.image?.altText}
									className="w-100 h-100"
								/>
							</Col>
							<Col xs={12} xl={6} className="my-24">
								<Row className="justify-content-center">
									<Col xs={11} sm={10}>
										<h2 className="text-3xl lato fw-700 mb-3">
											{whatWeDoTwoColumnSection?.heading}
										</h2>
										<span className="sl-underline d-block mb-5" />
										<div className="text-lg">
											{whatWeDoTwoColumnSection.description && (
												<SafeHtmlParser
													htmlContent={whatWeDoTwoColumnSection.description}
												/>
											)}
										</div>
										{whatWeDoTwoColumnSection?.cta &&
											whatWeDoTwoColumnSection.cta.url && (
												<Row>
													<SlButton
														className="text-lg fw-700"
														as="a"
														href={whatWeDoTwoColumnSection.cta.url}
													>
														{whatWeDoTwoColumnSection.cta.title}
													</SlButton>
												</Row>
											)}
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				)}

			{whatWeDoTextSection3 &&
				!checkPropertiesForNull(whatWeDoTextSection3, ["heading"]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<h2 className="text-3xl lato fw-700 mb-3">
									{whatWeDoTextSection3?.heading}
								</h2>
								<span className="sl-underline d-block mb-5" />
								<div className="text-lg mb-5">
									{whatWeDoTextSection3.description && (
										<SafeHtmlParser
											htmlContent={whatWeDoTextSection3?.description}
										/>
									)}
								</div>
								{whatWeDoTextSection3.cta && whatWeDoTextSection3.cta.url && (
									<Row>
										<SlButton
											variant="outline-primary"
											className="text-lg fw-700"
											as={Link}
											to={whatWeDoTextSection3.cta?.url}
										>
											{whatWeDoTextSection3.cta?.title}
										</SlButton>
									</Row>
								)}
							</Col>
						</Row>
					</Container>
				)}
			{whatWeDoCtaSection2 &&
				!checkPropertiesForNull(whatWeDoCtaSection2, ["heading"]) && (
					<CallNowBanner
						heading={whatWeDoCtaSection2?.heading}
						button={whatWeDoCtaSection2?.cta1}
						button2={whatWeDoCtaSection2?.cta2}
					/>
				)}
			{whatWeDoGoogleMapSection &&
				!checkPropertiesForNull(whatWeDoGoogleMapSection, ["heading"]) && (
					<AreaMap
						heading={whatWeDoGoogleMapSection?.heading}
						map={whatWeDoGoogleMapSection?.googleMap}
					/>
				)}
		</Layout>
	);
};

export default WhatWeDo;
